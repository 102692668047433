import './register.scss';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';

function Register() {
    const [form, setForm] = useState({
        userName: '',
        login: '',
        email: '',
        password: '',
        confirmPassword: '', // добавляем поле для подтверждения пароля
    });

    const [error, setError] = useState(''); // для хранения сообщения об ошибке

    const navigate = useNavigate();

    const changeHandler = (event) => {
        setForm({ ...form, [event.target.name]: event.target.value });
    }

    const registerHandler = async (event) => {
        event.preventDefault();
        
        // Проверка совпадения паролей перед отправкой
        if (form.password !== form.confirmPassword) {
            setError('Пароли не совпадают');
            return;
        }

        try {
            const response = await axios.post('/api/register', form, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            console.log(response.data);
            if (response.status === 201) {
                navigate('/auth', { replace: true });
            }
        } catch (error) {
            console.error(error.response ? error.response.data : error.message);
        }
    }

    return (
        <div className='auth-container'>
            <form className='auth-form-container' onSubmit={registerHandler}>
                <h1>Регистрация</h1>
                <div className="auth-inputs">
                    <input type="text" name='login' onChange={changeHandler} placeholder='Имя пользователя' required />
                    <input type="email" name='email' onChange={changeHandler} placeholder='Email' required />
                    <input type="password" name='password' onChange={changeHandler} placeholder='Введите пароль...' required />
                    <input type="password" name='confirmPassword' onChange={changeHandler} placeholder='Повторите пароль...' required />
                </div>
                {error && <p className="error-message">{error}</p>} {/* Сообщение об ошибке, если пароли не совпадают */}
                <button type='submit' className='auth-button'>Зарегистрироваться</button>
                <div className="auth-link-container">
                    <Link to='/auth' className='auth-link'>Есть аккаунт?</Link>
                </div>
            </form>
        </div>
    )
}

export default Register;
