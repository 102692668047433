import './myProfile.scss';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../header/header';
import Modal from 'react-modal';
import { jwtDecode } from 'jwt-decode';

Modal.setAppElement('#root');

function MyProfile() {
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);

    const [followers, setFollowers] = useState([]);
    const [following, setFollowing] = useState([]);
    const [showFollowersModal, setShowFollowersModal] = useState(false);
    const [showFollowingModal, setShowFollowingModal] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('jwtToken');
                const response = await axios.get('/api/myProfile', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setUserData(response.data);
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []); // добавьте пустой массив зависимостей, чтобы useEffect выполнялся только один раз

    const fetchFollowers = async () => {
        try {
            const token = localStorage.getItem('jwtToken');
            const decodedToken = jwtDecode(token);
            const userId = decodedToken.id; // убедитесь, что это правильное поле для идентификатора пользователя

            const res = await axios.get(`/api/user/${userId}/followers`);
            setFollowers(res.data);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchFollowing = async () => {
        try {
            const token = localStorage.getItem('jwtToken');
            const decodedToken = jwtDecode(token);
            const userId = decodedToken.id; // убедитесь, что это правильное поле для идентификатора пользователя

            const res = await axios.get(`/api/user/${userId}/following`);
            setFollowing(res.data);
        } catch (error) {
            console.error(error);
        }
    };

    const openFollowersModal = () => {
        fetchFollowers();
        setShowFollowersModal(true);
    };

    const openFollowingModal = () => {
        fetchFollowing();
        setShowFollowingModal(true);
    };

    const closeFollowersModal = () => setShowFollowersModal(false);
    const closeFollowingModal = () => setShowFollowingModal(false);

    if (loading) {
        return (
            <h1>Loading...</h1>
        );
    }

    if (!userData) {
        return (
            <h1>Пользователь не найден</h1>
        );
    }

    return (
        <>
            <Header />
            <div className="MyProfile">

                <div className='MyProfile-header'>
                    
                    <div className='MyProfileHeader-block1'>
                        <nav>{userData.user.login}</nav>
                    </div>

                    <div className='MyProfileHeader-block2'>

                        <div className='MyProfileHeader-block2_logoImage'>
                            <img src={userData.user.logoImage} alt='' />
                        </div>

                        <div className='MyProfileHeader-block2_nav'>

                            <div className='MyProfileHeader-block2_countInfo'>
                                <ul>
                                    <li>{userData.user.countPosts} <span>публикаций</span></li>
                                    <li onClick={openFollowersModal}>{userData.user.countFollowers} <span>подписчиков</span></li>
                                    <li onClick={openFollowingModal}>{userData.user.countFollowing} <span>подписок</span></li>
                                </ul>
                            </div>

                            <div className='MyProfileHeader-block2_subscribe'>
                                <Link to='/myProfile/edit'><button>Редактировать</button></Link>
                            </div>
                        </div>

                    </div>

                    <div className='MyProfileHeader-block3'>
                        <div className='MyProfileHeader-block3_userName'>{userData.user.userName}</div>
                        <div className='MyProfileHeader-block3_description'>{userData.user.description}</div>
                    </div>

                </div>
                
                <div className='MyProfile-footer'>
                    {userData.posts.map(post => (
                        <div key={post.id} className="userProfile-posts">
                            <Link to={`/userProfile/post/${post.id}`}>
                                {post.fileType.startsWith('image') ? (
                                    <img src={post.postUrl} alt={post.description} />
                                ) : post.fileType.startsWith('video') ? (
                                    <video>
                                        <source src={post.postUrl} type={post.fileType} />
                                    </video>
                                ) : null}
                            </Link>
                        </div>
                    ))}
                </div>
            </div>

            <Modal
                isOpen={showFollowersModal}
                onRequestClose={closeFollowersModal}
                contentLabel="Followers Modal"
                className="Modal"
                overlayClassName="Overlay"
            >
                <h2>Подписчики</h2>
                <ul>
                    {followers.map(follower => (
                        <Link to={`/userProfile/${follower.id}`} key={follower.id} >
                            <li key={follower.id}>
                                <img src={follower.logoImage} alt={follower.login} />
                                <span>{follower.login}</span>
                            </li>
                        </Link>
                    ))}
                </ul>
                <button onClick={closeFollowersModal}>Закрыть</button>
            </Modal>

            <Modal
                isOpen={showFollowingModal}
                onRequestClose={closeFollowingModal}
                contentLabel="Following Modal"
                className="Modal"
                overlayClassName="Overlay"
            >
                <h2>Подписки</h2>
                <ul>
                    {following.map(followed => (
                        <Link to={`/userProfile/${followed.id}`} key={followed.id} >
                            <li>
                                <img src={followed.logoImage} alt={followed.login} />
                                <span>{followed.login}</span>
                            </li>
                        </Link>
                    ))}
                </ul>
                <button onClick={closeFollowingModal}>Закрыть</button>
            </Modal>
        </>
    );
}

export default MyProfile;