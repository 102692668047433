import axios from 'axios';
import { useState, useEffect, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Header from '../../header/header';
import CommentModal from '../../hosting/Hosting/coment/comment';
import CommentIcon from './img/commentIcon.png';
import PostNav from './img/navigate.png';
import ActiveLike from './img/activeLike.png';
import UnactiveLike from './img/unactiveLike.png';

function CustomVideoPlayer({ src, type }) {
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const [showControls, setShowControls] = useState(false);

    const togglePlayPause = () => {
        if (isPlaying) {
            videoRef.current.pause();
        } else {
            videoRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    const toggleMute = () => {
        setIsMuted(!isMuted);
        videoRef.current.muted = !isMuted;
    };

    return (
        <div
            className="custom-video"
            onMouseEnter={() => setShowControls(true)}
            onMouseLeave={() => setShowControls(false)}
        >
            <video ref={videoRef} muted={isMuted} onClick={togglePlayPause} loop>
                <source src={src} type={type} />
                Your browser does not support the video tag.
            </video>
            <div className={`video-controls ${showControls ? 'visible' : ''}`}>
                <button className="play-pause" onClick={togglePlayPause}>
                    {isPlaying ? '❚❚' : '►'}
                </button>
                <button className="volume" onClick={toggleMute}>
                    {isMuted ? '🔇' : '🔊'}
                </button>
            </div>
        </div>
    );
}

function UserProfile() {
    const { postId } = useParams();
    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);
    const [commentPostId, setCommentPostId] = useState(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('jwtToken');
                const res = await axios.get(`/api/post/${postId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setPost(res.data);
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        };
        fetchData();
    }, [postId]);

    const handleLike = async (postId, liked) => {
        try {
            const token = localStorage.getItem('jwtToken');
            const url = liked ? `/api/unlike/${postId}` : `/api/like/${postId}`;
            const res = await axios.post(url, {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
    
            if (res.status === 200) {
                // Обновляем состояние
                setPost((prevPost) => ({
                    ...prevPost,
                    likes: !liked,
                    countLike: liked ? prevPost.countLike - 1 : prevPost.countLike + 1
                }));
            } else {
                console.error("Error liking/unliking post:", res.status, res.data);
            }
        } catch (error) {
            console.error("Error liking/unliking post:", error);
        }
    };

    const copyLink = () => {
        const postUrl = `${window.location.origin}/userProfile/post/${postId}`;
        navigator.clipboard.writeText(postUrl).then(() => {
            alert('Ссылка скопирована в буфер обмена!');
        }).catch((error) => {
            console.error("Ошибка при копировании ссылки:", error);
        });
    };

    const openComments = (postId) => {
        setCommentPostId(postId);
    };

    const closeComments = () => {
        setCommentPostId(null);
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
        const videoElement = document.querySelector('video');
        if (videoElement) {
            if (!isMenuOpen) {
                videoElement.classList.add('video-disabled');
            } else {
                videoElement.classList.remove('video-disabled');
            }
        }
    };

    if (loading) {
        return <h1>Загрузка поста...</h1>;
    }
    if (!post) {
        return <h1>Произошла ошибка...</h1>;
    }

    return (
        <>
            <Header />
            <div className="user-postPage">
                <div className="postPage-header">
                    <div className="postHeader-login">
                        <div className="postHeader-loginContainer">
                            <img src={post.user?.logoImage} alt="User Avatar" />
                        </div>
                        <Link to={`/userProfile/${post.user?.id}`}>
                            <span>{post.user?.login}</span>
                        </Link>
                    </div>

                    <div className='postHeader-nav'>
                        <img src={PostNav} alt="nav" onClick={toggleMenu}/>
                        <ul className={isMenuOpen ? 'active-menu' : 'unActive'} >
                            <li className='postHeaderNav-li' onClick={copyLink}>Копировать ссылку</li>
                        </ul>
                    </div>
                </div>

                <div className="postPage-main">
                    {post.post.fileType?.startsWith('image') ? (
                        <img src={post.post.postUrl} alt="Post Image" />
                    ) : post.post.fileType?.startsWith('video') ? (
                        <CustomVideoPlayer src={post.post.postUrl} type={post.post.fileType} />
                    ) : null}
                </div>

                <div className="postPage-footer">
                    <div className="pageFooter-countsInf">
                        <nav>
                            <img 
                                src={post.likes ? ActiveLike : UnactiveLike} 
                                alt="Like Icon" 
                                onClick={() => handleLike(post.post.id, post.likes)}
                            />
                            <span>{post.post.countLike}</span>

                        </nav>
                        <nav>
                            <img src={CommentIcon} alt="Comment Icon" onClick={() => openComments(post.post.id)} />
                            <span>{post.post.countComment}</span>
                        </nav>
                    </div>
                    <div className="pageFooter-underText">
                        <p className='pageFooter-paragraph'>{post.post.description}</p>
                    </div>
                </div>
            </div>
            {commentPostId && (
                <CommentModal postId={postId} onClose={closeComments} />
            )}
        </>
    );
}

export default UserProfile;
