// utility.js
export function isTokenExpired() {
    const expiryTime = localStorage.getItem('tokenExpiry');
    return expiryTime && Date.now() > parseInt(expiryTime, 10);
}

export function removeToken() {
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('tokenExpiry');
}
