import React from 'react';
import Hosting from '../Hosting/hosting';
import Header from '../../header/header';
import Nav from '../../navElements/nav';

function SubscribesPosts() {
    return (
        <>
        <Header/>
        <Nav/>
        <Hosting type="subscribesPosts" />
        </>
    );
}

export default SubscribesPosts;
