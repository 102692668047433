import './createPhoto.scss';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { FaCamera, FaUpload } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

function CreatePost() {
    const [file, setFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const [description, setDescription] = useState('');
    const [error, setError] = useState('');
    const [login, setLogin] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);

    const navigate = useNavigate();
    const goMainPage = () => {
        navigate('/', { replace: true });
    };

    useEffect(() => {
        const token = localStorage.getItem('jwtToken');
        const fetchData = async () => {
            try {
                const res = await axios.get('/api/user', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setLogin(res.data);
            } catch (error) {
                console.log('Ошибка при загрузке данных пользователя');
            }
        };

        fetchData();
    }, []);

    if (login === null) {
        return <h1>Loading</h1>;
    }

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];

        if (!selectedFile.type.startsWith('image') && !selectedFile.type.startsWith('video')) {
            setError('Допускаются только изображения и видеофайлы.');
            return;
        }

        setFile(selectedFile);
        setError('');

        const filePreview = URL.createObjectURL(selectedFile);
        setPreview(filePreview);

        if (selectedFile.type.startsWith('video')) {
            const videoElement = document.createElement('video');
            videoElement.src = filePreview;

            videoElement.onloadedmetadata = () => {
                if (videoElement.duration > 60) {
                    setFile(null);
                    setPreview(null);
                    setError('Видео должно быть не длиннее 1 минуты.');
                }
            };
        }
    };

    const handleUpload = async () => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('text', description);
        formData.append('login', login.login);

        try {
            setIsUploading(true);
            const response = await axios.post('/api/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(percentCompleted);
                }
            });
            goMainPage();
        } catch (error) {
            console.error('Error uploading file:', error);
        } finally {
            setIsUploading(false);
        }
    };

    return (
        <div className="file-upload-container">
            <div className="file-upload">
                <label className="file-upload-label">
                    <FaCamera size={30} />
                    <input type="file" onChange={handleFileChange} />
                    <span>Выберите фото или видео</span>
                </label>
                {error && <p className="error-message">{error}</p>}
                {preview && (
                    <>
                        <div className="file-preview">
                            {file.type.startsWith('image') ? (
                                <img src={preview} alt="Preview" />
                            ) : (
                                <video src={preview} controls />
                            )}
                        </div>
                        {/* Блок описания появляется только после выбора файла */}
                        <div className="description-container">
                            <textarea
                                className="description-input"
                                placeholder="Добавьте описание..."
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </div>
                        <button onClick={handleUpload} className="upload-button" disabled={isUploading}>
                            {isUploading ? 'Загрузка...' : (
                                <>
                                    <FaUpload size={20} style={{ marginRight: '10px' }} />
                                    Загрузить
                                </>
                            )}
                        </button>
                        {isUploading && <progress value={uploadProgress} max="100" />}
                    </>
                )}
            </div>
        </div>
    );
}

export default CreatePost;
