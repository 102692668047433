// src/components/NotificationsPage.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './notificationsPage.scss';

const NotificationsPage = () => {
    const [notifications, setNotifications] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const token = localStorage.getItem('jwtToken');
                const response = await axios.get('/api/notifications', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setNotifications(response.data);
                
                // После получения уведомлений отправляем запрос на обновление статуса прочтения
                await axios.put('/api/notifications/markAllRead', {}, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                // Обновляем уведомления на клиенте, чтобы они были помечены как прочитанные
                setNotifications((prevNotifications) =>
                    prevNotifications.map((n) => ({ ...n, read: true }))
                );

            } catch (error) {
                console.error('Ошибка при получении уведомлений:', error);
            }
        };

        fetchNotifications();
    }, []);

    const handleNotificationClick = (notification) => {
        // Навигация при клике на уведомление
        if (notification.type === 'comment' && notification.comment && notification.comment.id) {
            navigate(`/myProfile/post/${notification.post.id}`);
        } else if (notification.type === 'like' && notification.post && notification.post.id) {
            navigate(`/myProfile/post/${notification.post.id}`);
        } else if (notification.type === 'follow' && notification.sender && notification.sender.id) {
            navigate(`/userProfile/${notification.sender.id}`);
        } else {
            console.warn('Неверные данные уведомления:', notification);
        }
    };

    const getNotificationMessage = (notification) => {
        switch (notification.type) {
            case 'comment':
                return 'прокомментировал(-а) ваш пост';
            case 'like':
                return 'лайкнул(-а) ваш пост';
            case 'follow':
                return 'подписался(-лась) на вас';
            default:
                return 'сделал что-то';
        }
    };

    if (!notifications) {
        return <h1>Загрузка...</h1>;
    }

    return (
        <div className="notifications-page">
            <h1>Уведомления</h1>
            {notifications.length === 0 ? (
                <p>У вас нет уведомлений.</p>
            ) : (
                <ul>
                    {notifications.map((notification) => (
                        <li 
                            key={notification.id} 
                            onClick={() => handleNotificationClick(notification)}
                            className={notification.read ? 'read' : 'unread'}  // Классы для стилей
                        >
                            {notification.sender?.logoImage && (
                                <img src={notification.sender.logoImage} alt="" />
                            )}

                            <div className='content_notification'>
                                <span>
                                    <strong>{notification.sender?.login || 'Неизвестный пользователь'}</strong> 
                                    {getNotificationMessage(notification)}
                                </span>
                                <small>{new Date(notification.createdAt).toLocaleString()}</small>
                            </div>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default NotificationsPage;
