// redux/postsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
    posts: [],
    loading: false,
    error: null,
};

export const fetchPosts = createAsyncThunk('posts/fetchPosts', async (skip = 0, { getState }) => {
    const token = localStorage.getItem('jwtToken');
    const response = await axios.get('/api/posts', {
        headers: {
            Authorization: `Bearer ${token}`
        },
        params: {
            skip,
            limit: 10
        }
    });
    return response.data.posts;
});

export const likePost = createAsyncThunk('posts/likePost', async (postId, { getState }) => {
    const token = localStorage.getItem('jwtToken');
    const response = await axios.post(`/api/like/${postId}`, {}, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return { postId, likes: response.data.likes };
});

export const unlikePost = createAsyncThunk('posts/unlikePost', async (postId, { getState }) => {
    const token = localStorage.getItem('jwtToken');
    const response = await axios.post(`/api/unlike/${postId}`, {}, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return { postId, likes: response.data.likes };
});

const postsSlice = createSlice({
    name: 'posts',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchPosts.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchPosts.fulfilled, (state, action) => {
                state.loading = false;
                state.posts = state.posts.concat(action.payload);
            })
            .addCase(fetchPosts.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(likePost.fulfilled, (state, action) => {
                const post = state.posts.find(post => post._id === action.payload.postId);
                if (post) {
                    post.liked = true;
                    post.countLike++;
                }
            })
            .addCase(unlikePost.fulfilled, (state, action) => {
                const post = state.posts.find(post => post._id === action.payload.postId);
                if (post) {
                    post.liked = false;
                    post.countLike--;
                }
            });
    },
});

export default postsSlice.reducer;
