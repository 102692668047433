import './userProfile.scss';
import axios from 'axios';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Header from '../header/header';
import {jwtDecode} from 'jwt-decode';  // Исправил импорт на правильный
import Modal from 'react-modal';

Modal.setAppElement('#root');

function UserProfile() {
    const { userId } = useParams();
    const [user, setUser] = useState(null);
    const [posts, setPosts] = useState([]);
    const [followers, setFollowers] = useState([]);
    const [following, setFollowing] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isFollowing, setIsFollowing] = useState(undefined);  // Начальное состояние undefined
    const [showFollowersModal, setShowFollowersModal] = useState(false);
    const [showFollowingModal, setShowFollowingModal] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);

    const navigate = useNavigate();

    const fetchUserData = async (currentUserId) => {
        console.log("Fetching user data for userId:", userId);
        try {
            const token = localStorage.getItem('jwtToken');
            console.log("Token found:", token);

            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };

            const res = await axios.get(`/api/user/${userId}`, config);
            const fetchedUser = res.data.user;
            const fetchedPosts = res.data.posts;

            if (fetchedUser) {
                setUser(fetchedUser);
                setPosts(fetchedPosts);

                // Устанавливаем состояние подписки
                setIsFollowing(fetchedUser.isFollowing);
            } else {
                console.log("No valid user data found.");
                setUser(null);
            }

            setLoading(false);
        } catch (error) {
            console.error('Error fetching user data:', error);
            setLoading(false);
        }
    };

    const fetchFollowers = async () => {
        try {
            const token = localStorage.getItem('jwtToken');

            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };

            const res = await axios.get(`/api/user/${userId}/followers`, config);
            setFollowers(res.data);
        } catch (error) {
            console.error('Error fetching followers:', error);
        }
    };

    const fetchFollowing = async () => {
        console.log("Fetching following for userId:", userId);
        try {
            const token = localStorage.getItem('jwtToken');

            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };

            const res = await axios.get(`/api/user/${userId}/following`, config);
            console.log("Fetched following:", res.data);
            setFollowing(res.data);
        } catch (error) {
            console.error('Error fetching following:', error);
        }
    };

    useEffect(() => {
        console.log("useEffect triggered. userId:", userId);
        const fetchUser = async () => {
            try {
                const token = localStorage.getItem('jwtToken');
                if (token) {
                    const decodedToken = jwtDecode(token);
                    const currentUserId = decodedToken.id;

                    console.log("Decoded token:", decodedToken);
                    console.log("Current user ID:", currentUserId);

                    if (String(currentUserId) === String(userId)) {
                        setIsOwner(true);
                        console.log("User is the owner. Redirecting to /myProfile");
                        navigate('/myProfile');
                    } else {
                        setIsOwner(false);
                        await fetchUserData(currentUserId);
                        await fetchFollowers();
                        await fetchFollowing();
                    }
                } else {
                    console.log("No token found in localStorage");
                }
            } catch (error) {
                console.error('Error decoding token:', error);
                setLoading(false);
            }
        };

        fetchUser();
    }, [userId, navigate]);

    const handleFollow = async () => {
        if (isProcessing) return;
        setIsProcessing(true);

        console.log("Current isFollowing state before follow/unfollow:", isFollowing);

        try {
            const token = localStorage.getItem('jwtToken');
            if (!token) {
                console.error("No token found in localStorage");
                setIsProcessing(false);
                return;
            }

            const url = isFollowing ? `/api/unfollow/${userId}` : `/api/follow/${userId}`;
            console.log("Follow/unfollow URL:", url);

            const res = await axios.post(url, {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            console.log("Response from follow/unfollow:", res.data);

            // Обновление состояния подписки
            setIsFollowing(!isFollowing);
            console.log("Updated isFollowing state after follow/unfollow:", !isFollowing);

            // Обновление количества подписчиков в интерфейсе
            setUser(prevUser => ({
                ...prevUser,
                countFollowers: isFollowing
                    ? prevUser.countFollowers - 1
                    : prevUser.countFollowers + 1
            }));
            console.log("Updated user state with new countFollowers:", user);

        } catch (error) {
            console.error('Error updating follow status:', error);
        } finally {
            setIsProcessing(false);
        }
    };

    const openFollowersModal = () => {
        console.log("Opening followers modal");
        fetchFollowers();
        setShowFollowersModal(true);
    };

    const openFollowingModal = () => {
        console.log("Opening following modal");
        fetchFollowing();
        setShowFollowingModal(true);
    };

    const closeFollowersModal = () => {
        console.log("Closing followers modal");
        setShowFollowersModal(false);
    };

    const closeFollowingModal = () => {
        console.log("Closing following modal");
        setShowFollowingModal(false);
    };

    if (loading) {
        console.log("Loading state is true. Returning loading message.");
        return <h1>Загрузка пользователя</h1>;
    }

    if (!user) {
        console.log("User state is null. Returning 'User not found' message.");
        return <h1>Пользователь не найден</h1>;
    }

    console.log("Rendering user profile with data:", user);

    return (
        <>
            <Header />
            <div className="UserProfile">

                <div className='userProfile-header'>
                    
                    <div className='userProfileHeader-block1'>
                        <nav>{user.login}</nav>
                    </div>

                    <div className='userProfileHeader-block2'>

                        <div className='userProfileHeader-block2_logoImage'>
                            <img src={user.logoImage} alt='' />
                        </div>

                        <div className='userProfileHeader-block2_nav'>

                            <div className='userProfileHeader-block2_countInfo'>
                                <ul>
                                    <li>{user.countPosts} <span>публикаций</span></li>
                                    <li onClick={openFollowersModal}>{user.countFollowers} <span>подписчиков</span></li>
                                    <li onClick={openFollowingModal}>{user.countFollowing} <span>подписок</span></li>
                                </ul>
                            </div>

                            <div className='userProfileHeader-block2_subscribe'>
                                {!isOwner && isFollowing !== undefined && (
                                    <button onClick={handleFollow} disabled={isProcessing}>
                                        {isFollowing ? 'Вы подписаны' : 'Подписаться'}
                                    </button>
                                )}
                            </div>
                        </div>

                    </div>

                    <div className='userProfileHeader-block3'>
                        <div className='userProfileHeader-block3_userName'>{user.userName}</div>
                        <div className='userProfileHeader-block3_description'>{user.description}</div>
                    </div>

                </div>
                
                <div className='userProfile-footer'>
                    {posts.map(post => (
                        <div key={post.id} className="userProfile-posts">
                            <Link to={`/userProfile/post/${post.id}`}>
                                {post.fileType.startsWith('image') ? (
                                    <img src={post.postUrl} alt={post.description} />
                                ) : post.fileType.startsWith('video') ? (
                                    <video>
                                        <source src={post.postUrl} type={post.fileType} />
                                    </video>
                                ) : null}
                            </Link>
                        </div>
                    ))}
                </div>
            </div>

            <Modal
                isOpen={showFollowersModal}
                onRequestClose={closeFollowersModal}
                contentLabel="Followers Modal"
                className="Modal"
                overlayClassName="Overlay"
            >
                <h2>Подписчики</h2>
                <ul>
                    {followers.map(follower => (
                        <Link to={`/userProfile/${follower.id}`} key={follower.id} >
                            <li>
                                <img src={follower.logoImage} alt={follower.login} />
                                <span>{follower.login}</span>
                            </li>
                        </Link>
                    ))}
                </ul>
                <button onClick={closeFollowersModal}>Закрыть</button>
            </Modal>

            <Modal
                isOpen={showFollowingModal}
                onRequestClose={closeFollowingModal}
                contentLabel="Following Modal"
                className="Modal"
                overlayClassName="Overlay"
            >
                <h2>Подписки</h2>
                <ul>
                    {following.map(followed => (
                        <Link to={`/userProfile/${followed.id}`} key={followed.id} >
                            <li>
                                <img src={followed.logoImage} alt={followed.login} />
                                <span>{followed.login}</span>
                            </li>
                        </Link>
                    ))}
                </ul>
                <button onClick={closeFollowingModal}>Закрыть</button>
            </Modal>
        </>
    );
}

export default UserProfile;
