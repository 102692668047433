import { Route, Routes } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { isTokenExpired, removeToken } from './utility'; // Импортируйте функции проверки
import axios from 'axios';
import MainPage from './MainPage/MainPage';
import Register from '../components/auth/register/register';
import Auth from '../components/auth/authenticate/auth';
import MyProfile from '../components/myProfile/myProfile';
import CreatePost from '../components/CreatePost/createPost';
import UserProfile from '../components/userProfile/userProfile';
import EditProfile from '../components/myProfile/reductProfile/reductProfile';
import UserProfilePost from '../components/userProfile/userProfilePost/userProfilePost';
import MyProfilePost from '../components/myProfile/myProfilePost/myProfilePost';
import PhotoHosting from '../components/hosting/PhotoHosting/PhotoHosting';
import VideoHosting from '../components/hosting/VideoHosting/VideoHosting';
import SubscribesPosts from '../components/hosting/SubcribesPosts/Subscribes';
import ProtectedRoute from './ProtectedRoute/Protected';
import NotificationsPage from '../components/notification/notificationPage';

function useRoutes() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('jwtToken');
                if (token && !isTokenExpired()) {
                    setIsLoggedIn(true);
                } else {
                    removeToken();
                    setIsLoggedIn(false);
                }
            } catch (error) {
                console.log(error);
                removeToken();
                setIsLoggedIn(false);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <Routes>
            {/* Маршруты для неавторизованных пользователей */}
            <Route path="/register" element={<Register />} />
            <Route path="/auth" element={<Auth />} />

            {/* Маршруты для авторизованных пользователей */}
            <Route element={<ProtectedRoute isLoggedIn={isLoggedIn} />}>
                <Route path="/createPost" element={<CreatePost />} />
                <Route path="/myProfile/edit" element={<EditProfile />} />
                <Route path="/myProfile/post/:postId" element={<MyProfilePost />} />
                <Route path="/photo" element={<PhotoHosting />} />
                <Route path="/video" element={<VideoHosting />} />
                <Route path='/subscriptions' element={<SubscribesPosts/>}/>
                <Route path="/userProfile/post/:postId" element={<UserProfilePost />} />
                <Route path="/myProfile" element={<MyProfile />} />
                <Route path='/userProfile/:userId' element={<UserProfile />} />
                <Route path='/notification' element={<NotificationsPage />} />
                <Route path="/" element={<MainPage />} />
            </Route>
        </Routes>
    );
}

export default useRoutes;
