import React from 'react';
import Hosting from '../Hosting/hosting';
import Header from '../../header/header';
import Nav from '../../navElements/nav';

function VideoHosting() {
    return (
        <>
        <Header/>
        <Nav/>
        <Hosting type="video" />
        </>
    );
}

export default VideoHosting;
