import './header.scss';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useState, useEffect } from 'react';
import Search from './img/searchButton.png';
import Icon from './img/iconAdd.png';

function Header() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userData, setUserData] = useState(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isAddMat, setIsAddMat] = useState(false);

    //Acc delete

    const [deleteLoading, setDeleteLoading] = useState(false);
    const [error, setError] = useState(null);

    //Search

    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);

    const [showSearchModal, setShowSearchModal] = useState(false);

    // Notification

    const [notifications, setNotifications] = useState([]);
    const [hasUnread, setHasUnread] = useState(false);

    const navigate = useNavigate();

    const goAuth = () => {
        navigate('/auth', { replace: true });
    };

    const deleteToken = () => {
        localStorage.removeItem('jwtToken');
        goAuth();
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const toggleIcon = () => {
        setIsAddMat(!isAddMat);
    };

    const handleDeleteAcc = async () => {
        const confirmDelete = window.confirm('Вы уверены, что хотите удалить аккаунт безвозвратно?');
        if (!confirmDelete) {
            return;
        }

        setDeleteLoading(true);
        setError(null);

        try {
            const token = localStorage.getItem('jwtToken');
            await axios.delete('/api/deleteAccount', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            localStorage.removeItem('jwtToken');
            navigate('/auth');
        } catch (error) {
            setError('Ошибка при удалении аккаунта');
            console.log(error);
        } finally {
            setDeleteLoading(false);
        }
    };

    const handleSearchSubmit = (event) => {
        event.preventDefault();
        setShowSearchModal(false);
        fetchSearchResults();
    }

    const fetchSearchResults = async () => {
        if (searchQuery.trim() === '') {
            setSearchResults([]);
            return;
        }

        try {
            const res = await axios.get('/api/searchUsers', {
                params: { query: searchQuery }
            });
            setSearchResults(res.data);
            setShowSuggestions(true);
        } catch (error) {
            console.error('Ошибка при поиске пользователя', error);
        }
    };

    const handleInputChange = (event) => {
        setSearchQuery(event.target.value);
        fetchSearchResults();
    };

    const handleBlur = () => {
        setTimeout(() => setShowSuggestions(false), 100);
    };

    const handleUserClick = (userId) => {
        navigate(`/userProfile/${userId}`);
        console.log('Навигация на', userId)
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('jwtToken');

                if (!token) {
                    return goAuth();
                }

                const res = await axios.get('/api/user', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                if (token) {
                    setIsLoggedIn(true);
                }

                setUserData(res.data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    // Функция для получения уведомлений и проверки на непрочитанные
    const fetchNotifications = async () => {
        try {
            const token = localStorage.getItem('jwtToken');
            const res = await axios.get('/api/notifications', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setNotifications(res.data);
            const hasUnreadNotifications = res.data.some(notification => !notification.read);
            setHasUnread(hasUnreadNotifications);
        } catch (error) {
            console.error('Ошибка при получении уведомлений', error);
        }
    };

    useEffect(() => {
        fetchNotifications();
    }, []);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (!event.target.closest('.header-search')) {
                setShowSearchModal(false);
                setShowSuggestions(false);
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    return (
        <header className='header'>
            <div className="header-logo"><span>SF</span><nav><Link to='/'>ShareFace</Link></nav></div>
            {isLoggedIn ? (
                <div className="header-menu">

                    <div className="header-search">
                        <img 
                            src={Search}
                            alt="Search"
                            className='search-icon'
                            onClick={() => setShowSearchModal(true)}
                        />
                        {showSearchModal && (
                            <div className="search-modal">
                                <form onSubmit={handleSearchSubmit}>
                                    <input
                                        type="text"
                                        value={searchQuery}
                                        onChange={handleInputChange}
                                        placeholder="Введите запрос..."
                                        autoComplete="off"
                                    />
                                </form>
                            
                                {/* Отображение результатов поиска */}
                                {showSuggestions && searchResults.length > 0 && (
                                    <ul className="suggestions-list">
                                        {searchResults.map((user) => (
                                            <li key={user.id} onClick={() => handleUserClick(user.id)}>
                                                <img src={user.logoImage} alt="User Avatar" />
                                                <span>{user.login}</span>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        )}
                    </div>

                    <Link to={'/createPost'}><span onClick={toggleIcon}><img src={Icon} alt="" /></span></Link>

                    <nav onClick={toggleMenu}><img src={userData.logoImage} alt="user-img" /></nav>
                    <ul className={isMenuOpen ? 'active-menu' : 'header-ul'}>
                        <li className="header-li"><Link to='/myProfile'>{userData.login}</Link></li>
                        <li className="header-li">
                            <Link to='/notification'>Уведомления</Link>
                            {hasUnread && <span className="notification-dot"></span>}
                        </li>
                        <li className="header-li" onClick={deleteToken}>Выйти из аккаунта</li>
                        <li className='header-li deleteAcc'>
                            <button onClick={handleDeleteAcc} disabled={deleteLoading}>
                                {deleteLoading ? "Удаление..." : 'Удалить аккаунт'}
                            </button>
                        </li>
                    </ul>
                </div>
            ) : (
                <div className="header-menu">
                    <span className='header-menuAuth'><Link to={'/auth'}>Войти</Link></span>
                </div>
            )}
        </header>
    );
}

export default Header;
