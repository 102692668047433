import './reductProfile.scss';
import axios from 'axios';
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';

function EditProfile() {
    const [userData, setUserData] = useState({
        login: '',
        userName: '',
        description: '',
        logoImage: '',
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const imageRef = useRef(null);
    const [cropper, setCropper] = useState(null);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const token = localStorage.getItem('jwtToken');
                const res = await axios.get('/api/user', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                const fetchedData = res.data || {};
                setUserData({
                    login: fetchedData.login || '',
                    userName: fetchedData.userName || '',
                    description: fetchedData.description || '',
                    logoImage: fetchedData.logoImage || '',
                });
                setLoading(false);
            } catch (error) {
                console.error('Ошибка при загрузке данных пользователя', error);
                setError('Ошибка при загрузке данных пользователя');
                setLoading(false);
            }
        };
        fetchUserData();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setUserData((prevData) => ({
                    ...prevData,
                    logoImage: reader.result,
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        if (imageRef.current && userData.logoImage) {
            if (cropper) {
                cropper.destroy(); // Удаление предыдущего экземпляра cropper, если он существует
            }
            const cropperInstance = new Cropper(imageRef.current, {
                aspectRatio: 1,
                viewMode: 1,
                autoCropArea: 1,
                responsive: true,
                scalable: false,
                zoomable: false,
            });
            setCropper(cropperInstance);
        }
    }, [userData.logoImage]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
    
        try {
            const token = localStorage.getItem('jwtToken');
            const formData = new FormData();
    
            formData.append('login', userData.login);
            formData.append('userName', userData.userName);
            formData.append('description', userData.description);
    
            // Проверяем, было ли загружено новое изображение и инициализирован ли cropper
            if (cropper && userData.logoImage) {
                const canvas = cropper.getCroppedCanvas();
                if (canvas) {
                    // Преобразуем canvas в blob и отправляем данные
                    canvas.toBlob((blob) => {
                        formData.append('logoImage', blob);
                        submitData(formData, token);  // Отправка данных после обрезки изображения
                    }, 'image/jpeg');
                } else {
                    // Если canvas отсутствует, просто отправляем данные
                    submitData(formData, token);
                }
            } else {
                // Если аватарка не менялась, просто отправляем данные
                submitData(formData, token);
            }
        } catch (error) {
            console.error('Ошибка при обновлении данных пользователя', error);
            setError('Ошибка при обновлении данных пользователя');
        } finally {
            setLoading(false);
        }
    };

    const submitData = async (formData, token) => {
        await axios.put('/api/updateUser', formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        });

        navigate('/myProfile');
    };

    if (loading) {
        return <h1>Загрузка...</h1>;
    }

    if (error) {
        return <h1>{error}</h1>;
    }

    return (
        <div className="EditProfile">
            <h1>Редактировать профиль</h1>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="login">Логин</label>
                    <input
                        type="text"
                        id="login"
                        name="login"
                        value={userData.login}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="userName">Имя пользователя</label>
                    <input
                        type="text"
                        id="userName"
                        name="userName"
                        value={userData.userName}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="description">Описание</label>
                    <textarea
                        id="description"
                        name="description"
                        value={userData.description || ''}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="logoImage">Аватарка</label>
                    <input
                        type="file"
                        id="logoImage"
                        name="logoImage"
                        onChange={handleFileChange}
                    />
                </div>
                {userData.logoImage && (
                    <div className="image-cropper">
                        <img ref={imageRef} src={userData.logoImage} alt="Crop preview" />
                    </div>
                )}
                <button type="submit" disabled={loading}>
                    {loading ? 'Сохранение...' : 'Сохранить'}
                </button>
            </form>
        </div>
    );
}

export default EditProfile;
