import './nav.scss';
import {Link} from 'react-router-dom'

function Nav(){
    return(
        <div className="nav-Elements">
            <ul className="navUl">
                <li><Link to={'/video'}>Видео</Link></li>
                <li><Link to={'/photo'}>Фотографии</Link></li>
                <li><Link to={'/'}>Главное</Link></li>
                <li><Link to={'/subscriptions'}>Подписки</Link></li>
            </ul>
        </div>
    )
}

export default Nav;