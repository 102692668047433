import './App.scss';
import {BrowserRouter, Navigate} from 'react-router-dom';
import useRoutes from './routes/route';
import { useEffect } from 'react';

function App() {
  const route = useRoutes()

  return (
      <BrowserRouter>
        <div className="App">
          {route}
        </div>
      </BrowserRouter>
  );
}

export default App;